<template>
  <div class="content-wrap">
    <div class="table-container">
      <div class="table-content">
        <div class="abilityBox ml-30">
          <div class="title">
            <span class="icon"></span>
            详情
          </div>
        </div>
        <el-form ref="form" :model="abilityData" label-width="100px" disabled>
          <!-- <div class="abilityBox">
            <el-row>
              <el-col :span="24"> -->
          <el-form-item label="名称">
            <el-input v-model="abilityData.Name" style="width:100%"></el-input>
          </el-form-item>
          <el-form-item label="描述">
            <el-input type="textarea" :rows="10" v-model="abilityData.Description"></el-input>
          </el-form-item>
          <!-- </el-col>
            </el-row>
          </div> -->
          <!-- <div class="abilityBox">
            <el-row>
              <el-col :span="24">
                <el-form-item label="描述">
                  <el-input type="textarea" :rows="10" v-model="abilityData.Description"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div> -->
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "abilityDetail",
  props: {
    abilityData: {
      type: Object
      // required: true
    }
  },
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {}
};
</script>

<style scoped lang="scss">
.content-wrap {
  box-sizing: border-box;
  // padding: 24px;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  .table-container {
    flex: 1;
    height: 100%;
    position: relative;
    .table-content {
      position: absolute;
      width: 100%;
      height: 100%;
      .abilityBox {
        display: flex;
        // margin-left: 96px;
        .title {
          height: 60px;
          font-size: 20px;
          color: #3d5afe;
          display: flex;
          align-items: center;
          .icon {
            display: inline-block;
            width: 4px;
            height: 20px;
            background: #3d5afe;
            border-radius: 2px;
            margin-right: 6px;
          }
        }
      }
    }
  }
}
</style>
