<template>
  <div class="ability">
    <div class="ability-left">
      <div class="addTypeBox">
        <div class="addType" @click="handleAddProfessional">添加</div>
      </div>

      <CustomTree
        :treeData="AbilityOptions"
        @select="handleSelect"
        v-slot="dataDefalut"
      >
        <!-- <span>{{ dataDefalut.data }}</span> -->
        <!-- v-if="dataDefalut.data.Level > 0" -->
        <el-dropdown @command="handleCommand" trigger="click">
          <span class="el-dropdown-link">
            <i class="el-icon-more"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="add" v-if="dataDefalut.data.Level < 3"
              >添加</el-dropdown-item
            >
            <el-dropdown-item v-if="abilityData.Level !== 0" command="edit"
              >修改</el-dropdown-item
            >
            <el-dropdown-item v-if="abilityData.Level !== 0" command="delete"
              >删除</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </CustomTree>
    </div>
    <div class="ability-right" v-loading="loading">
      <AbilityTabs :abilityData="categoryInfo" />
    </div>
    <el-dialog
      title="添加能力"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="abilityForm" label-width="80px">
        <el-form-item label="名称">
          <el-input
            placeholder="请输入名称"
            v-model="abilityForm.Name"
          ></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input
            type="textarea"
            :rows="10"
            placeholder="请输入描述"
            v-model="abilityForm.Description"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleFormBtn">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="专业添加"
      :visible.sync="majorDialogVisible"
      width="50%"
      :before-close="majorHandleClose"
    >
      <el-form
        ref="majorForm"
        :model="majorForm"
        :rules="majorRules"
        label-width="80px"
      >
        <el-form-item label="选择专业" prop="MajorId">
          <el-cascader
            :show-all-levels="false"
            style="width: 100%;"
            :options="MajorOptions"
            v-model="majorForm.MajorId"
            filterable
            :props="props"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="一级能力名称" prop="Name">
          <el-input
            placeholder="请输入一级能力名称"
            v-model="majorForm.Name"
          ></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input
            type="textarea"
            :rows="10"
            placeholder="请输入描述"
            v-model="majorForm.Description"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="majorDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleMajorFormBtn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import CustomTree from "@/components/Common/CustomTree";
import AbilityTabs from "./components/AbilityTabs";
import { arrayToTree } from "@/utils/util";
import { mapGetters, mapState, mapActions } from "vuex";
import { getAbilityList } from "@/api/ability";
import {
  getCategoryInfo,
  insertCategory,
  deleteCategory,
  updateCategory,
  getContentInfo,
  insertContent
} from "@/api/ability";
export default {
  name: "ability",
  props: {},
  components: {
    CustomTree,
    AbilityTabs
  },
  data() {
    return {
      myMajorId: null,
      abilityData: {},
      loading: false,
      isAnew: false,
      majorDialogVisible: false,
      dialogVisible: false,
      props: {
        checkStrictly: true,
        label: "Name",
        value: "Id",
        emitPath: false,
        children: "Children"
      },
      abilityId: "",
      majorForm: {
        MajorId: "",
        Name: "",
        ParentId: null,
        Level: 0,
        Sort: 0,
        Description: ""
      },
      majorRules: {
        MajorId: [{ required: true, message: "请选择专业", trigger: "change" }],
        Name: [
          { required: true, message: "请输入一级能力名字", trigger: "blur" }
        ]
      },
      abilityForm: {
        MajorId: "",
        ParentId: null,
        Level: null,
        Sort: null,
        Name: "",
        Description: ""
      },
      AbilityList: [],
      abilityState: "add",
      FormBtnState: "",
      abilityComponent: "AbilityTabs",
      AbilityOptions: [],
      MajorOptions: [],
      categoryInfo: {},
      contentInfo: []
    };
  },
  computed: {
    ...mapState("common", {
      MajorList: state => state.Major //
    })
  },
  watch: {},
  async created() {
    await this.getAbilityList();
  },
  methods: {
    async getAbilityList() {
      try {
        await getAbilityList().then(async ({ Result }) => {
          this.AbilityList = await Object.freeze(Result);
          await this.getAbility();
        });
      } catch (error) {
        console.log(error);
      }
    },
    getAbility() {
      let dataList = [];
      this.AbilityList.forEach((el, i) => {
        if (el.Id) {
          dataList.push(el);
        }
      });
      this.AbilityOptions = arrayToTree(dataList);
      this.MajorOptions = arrayToTree(this.MajorList);
    },
    async getCategoryInfo(id) {
      this.loading = true;
      try {
        const { Result } = await getCategoryInfo(id);
        this.categoryInfo = Result;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async getContentInfo(id) {
      this.loading = true;
      try {
        const { Result } = await getContentInfo(id);
        this.categoryInfo = Result;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    handleAddProfessional() {
      this.majorDialogVisible = true;
    },
    // 新增携带专业一级能力
    async handleMajorFormBtn() {
      this.$refs["majorForm"].validate(async valid => {
        if (valid) {
          this.loading = true;
          this.majorForm.Level = 1;
          this.majorForm.Sort = this.AbilityOptions.length + 1;
          try {
            const { Result } = await insertCategory(this.majorForm);
            this.loading = false;
            this.$message.success("添加成功");
            await this.getAbilityList();
            this.majorHandleClose();
          } catch (error) {
            this.loading = false;
            console.log(error);
          }
        } else {
          this.$message.error("您的表单没有输入完整!");
          return false;
        }
      });
    },
    async handleFormBtn(state) {
      if (this.FormBtnState === "add") {
        this.loading = true;
        // this.abilityForm.MajorId = this.abilityData.Id;
        // 当前数据级别
        if (this.abilityData.Level == 0) {
          this.abilityForm.Level = 1;
          this.abilityForm.MajorId = this.myMajorId;
          this.abilityForm.ParentId = null;
          this.abilityForm.Sort = this.abilityData.Children
            ? this.abilityData.Children.length + 1
            : 1;
        } else if (this.abilityData.Level == 1) {
          this.abilityForm.ParentId = this.abilityData.Id;
          this.abilityForm.Level = 2;
          this.abilityForm.MajorId = this.myMajorId;
          // console.log(this.abilityData);
          this.abilityForm.Sort = this.abilityData.Children
            ? this.abilityData.Children.length + 1
            : 1;
        } else if (this.abilityData.Level == 2) {
          delete this.abilityForm.MajorId;
          this.abilityForm.ParentId = this.abilityData.Id;
          this.abilityForm.Level = 3;
          this.abilityForm.Sort = this.abilityData.Children
            ? this.abilityData.Children.length + 1
            : 1;
          this.abilityForm = {
            ...this.abilityForm,
            AbilityCategoryId: this.abilityData.Id
          };
          try {
            const { Result } = await insertContent(this.abilityForm);
            this.loading = false;
            this.dialogVisible = false;
            this.handleClose();
            this.getAbilityList();
          } catch (error) {
            console.log(error);
          }
          return false;
        }
        try {
          const { Result } = await insertCategory(this.abilityForm);
          this.loading = false;
          this.dialogVisible = false;
          this.getAbilityList();
        } catch (error) {
          console.log(error);
        }
      } else if (this.FormBtnState === "edit") {
        this.loading = true;
        try {
          // console.log(this.abilityForm);
          this.abilityForm.MajorId = this.abilityData.Id;
          const { Result } = await updateCategory(this.abilityForm);
          this.$message.success("修改成功");
          this.handleClose();
          this.getAbilityList();
          this.loading = false;
        } catch (error) {
          console.log(error);
        }
      }
    },
    //
    async handleCommand(val) {
      this.abilityState = val;
      this.abilityComponent = "";
      if (this.abilityState === "add") {
        this.dialogVisible = true;
        this.FormBtnState = "add";
        // this.$router.go(0);
      } else if (this.abilityState === "edit") {
        this.dialogVisible = true;
        this.FormBtnState = "edit";
        delete this.abilityData.Children;
        this.abilityForm = this.abilityData;
        // this.$router.go(0);
      } else if (this.abilityState === "delete") {
        this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(async () => {
            this.loading = true;
            try {
              const { Result } = await deleteCategory(this.abilityData.Id);
              this.loading = false;
              // this.$router.go(0);
              this.getAbilityList();
              this.$message.success("删除成功!");
            } catch (error) {
              console.log(error);
            }
          })
          .catch(() => {
            this.$message.info("已取消删除");
          });
      }
    },
    handleSelect(data) {
      this.abilityData = data;
      // console.log(data.Level);
      if (data.Level === 0) this.myMajorId = data.Id;
      // console.log(111, this.myMajorId);
      // console.log(`object`, data);
      // console.log("当前数据", data);
      if (data.Level > 0 && data.Level < 3) {
        this.getCategoryInfo(data.Id);
      } else if (data.Level > 2) {
        this.getContentInfo(data.Id);
      } else {
        this.categoryInfo = data;
      }
    },
    majorHandleClose() {
      this.majorDialogVisible = false;
      this.majorForm = {
        MajorId: "",
        Name: "",
        ParentId: null,
        Level: 0,
        Sort: 0,
        Description: ""
      };
    },
    handleClose() {
      this.dialogVisible = false;
      this.abilityForm = {
        MajorId: "",
        ParentId: null,
        Level: null,
        Sort: null,
        Name: "",
        Description: ""
      };
    }
  },
  mounted() {}
};
</script>

<style scoped lang="scss">
.ability {
  min-height: 73vh;
  display: flex;
  .ability-left {
    // padding: 24px;
    width: 320px;
  }
  .ability-right {
    width: 76%;
    padding: 24px;
    border-left: 1px solid #ccc;
  }
}
.addTypeBox {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  user-select: none;
  cursor: pointer;
  padding: 0 24px;
  margin-top: 10px;
  box-sizing: border-box;

  .addType {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    color: #3d5afe;
    border: 1px solid rgba(61, 90, 254, 0.35);
    background-color: rgba(61, 90, 254, 0.15);
    &:hover {
      background-color: #3d5afe;
      color: #fff;
    }
  }
}
</style>
